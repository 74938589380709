import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';

let vp = null;

if (process.env.REACT_APP_TEST === 'TRUE') {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: 'us-east-1_BAbQSFOm8',
        userPoolClientId: '32ugjhuee7grvtjqes1fsv9u8m'
      }
    }
  });
}
else {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: '##_COG_USER_POOL_##',
        userPoolClientId: '##_COG_CLIENT_ID_##'
      }
    }
  });
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


reportWebVitals();
