import React, { Component } from 'react';
import BasicForm from '../../sharedComponents/basicForm';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

const scheduleInputForm = [
  { label: 'Execution Day', type: 'multiSelect', name: 'day', options: days },
  { label: 'Execution Hour (UTC)', type: 'multiSelect', name: 'hour', options: hours },
  { label: 'Execution Minute', type: 'multiSelect', name: 'minute', options: minutes },
];

const styles = {
  additionalOptionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '5px',
    color: '#FFFFFF',
    border: '2px solid #dddddd',
  },
};

class ScheduleMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule_minutes: [],
      schedule_hours: null,
      schedule_days: null,
    };
  }

  handleScheduleChange = (formValues) => {
    this.setState({
      schedule_minutes: formValues.minute,
      schedule_hours: formValues.hour,
      schedule_days: formValues.day,
    });

    const { onChange } = this.props;
    if (onChange) {
      onChange(formValues);
    }
  }

  render() {
    return (
      <div style={styles.additionalOptionsContainer}>
        <BasicForm 
          fields={scheduleInputForm} 
          header='Schedule Setup'
          onChange={this.handleScheduleChange}
          width="600px"
        />
      </div>
    );
  }
}

export default ScheduleMethod;
