import React, { Component } from 'react';

const styles = {
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#e0e0df',
    borderRadius: '5px',
    overflow: 'hidden',
    height: '30px',
  },
  progressBarFiller: {
    height: '100%',
    backgroundColor: '#007bff',
    textAlign: 'center',
    lineHeight: '30px',
    color: 'white',
    transition: 'width 0.2s ease-in',
  },
  progressText: {
    marginLeft: '10px',
    color: '#333',
    fontWeight: 'bold',
  },
};

class CreditBar extends Component {
  render() {
    const { current, total } = this.props;
    const percentage = (current / total) * 100;

    return (
      <div>
        <div style={styles.progressBarContainer}>
          <div style={{ ...styles.progressBarFiller, width: `${percentage}%` }}>
            {`${current}/${total}`}
          </div>
        </div>
        <div style={styles.progressText}>
          {`${current}/${total}`}
        </div>
      </div>
    );
  }
}

export default CreditBar;
