import React, { Component } from 'react';

const styles = {
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '20px',
    color: '#FFFFFF'
  },
  form: {
    backgroundColor: '#333',
    padding: '20px',
    borderRadius: '8px',
    color: '#FFFFFF',
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    color: '#FFFFFF',
    marginRight: '10px',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    paddingRight: '10px',
    borderRight: '1px solid #dddddd',
  },
  input: {
    flex: '1',
    padding: '8px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #dddddd',
    backgroundColor: '#555',
    color: '#FFFFFF',
  },
  select: {
    flex: '1',
    padding: '8px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #dddddd',
    backgroundColor: '#555',
    color: '#FFFFFF',
  },
  button: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#000',
    color: '#FFFFFF',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  header: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
  },
  sliderValue: {
    marginLeft: '10px',
    color: '#FFFFFF',
  }
};

class BasicForm extends Component {
  constructor(props) {
    super(props);
    const initialState = props.fields.reduce((state, field) => {
      if (field.type === 'multiSelect') {
        state[field.name] = field.defaultValue || [];
      } else if (field.type === 'range') {
        state[field.name] = field.defaultValue || field.min || '';
      } else if (field.type !== 'select') {
        state[field.name] = field.defaultValue || '';
      }
      return state;
    }, {});

    this.state = {
      formValues: initialState,
      maxLabelWidth: 0,
    };
  }

  componentDidMount() {
    this.updateMaxLabelWidth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fields !== this.props.fields) {
      this.updateMaxLabelWidth();
    }
  }

  updateMaxLabelWidth = () => {
    const maxLabelWidth = Math.max(...this.props.fields.map(field => field.label.length));
    this.setState({ maxLabelWidth });
  }

  handleChange = (e) => {
    const { name, value, options, type } = e.target;
    let updatedValue;

    if (type === 'select-multiple') {
      updatedValue = Array.from(options).filter(option => option.selected).map(option => option.value);
    } else {
      updatedValue = value;
    }

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [name]: updatedValue
      }
    }), () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.formValues);
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.formValues);
    }
  }

  handleButtonClick = (callback) => {
    if (callback) {
      callback(this.state.formValues);
    }
  }

  render() {
    const { header, fields, width, buttons } = this.props;
    const { formValues, maxLabelWidth } = this.state;

    return (
      <div style={{ ...styles.formContainer, width: width || 'auto' }}>
        <form style={{ ...styles.form, width: width || 'auto' }} onSubmit={this.handleSubmit}>
          {header && <div style={styles.header}>{header}</div>}

          {fields.map((field, index) => (
            <div style={styles.formGroup} key={index}>
              <label
                style={{ ...styles.label, width: `${maxLabelWidth}ch` }}
                htmlFor={field.name}
              >
                {field.label}
              </label>
              {field.type === 'select' || field.type === 'multiSelect' ? (
                <select
                  id={field.name}
                  name={field.name}
                  value={formValues[field.name]}
                  onChange={this.handleChange}
                  style={styles.select}
                  multiple={field.type === 'multiSelect'}
                >
                  {field.options.map((option, optionIndex) => (
                    <option key={optionIndex} value={option}>{option}</option>
                  ))}
                </select>
              ) : (
                <div style={field.type === 'range' ? styles.sliderContainer : { flex: '1', display: 'flex' }}>
                  <input
                    type={field.type}
                    id={field.name}
                    name={field.name}
                    value={formValues[field.name]}
                    onChange={this.handleChange}
                    style={styles.input}
                    min={field.min}
                    max={field.max}
                  />
                  {field.type === 'range' && (
                    <div style={styles.sliderValue}>
                      {formValues[field.name]}
                    </div>
                  )}
                  {field.showButton && (
                    <button
                      type="button"
                      style={styles.button}
                      onClick={() => this.handleButtonClick(field.buttonCallback)}
                    >
                      {field.buttonLabel || 'Action'}
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
          {buttons && buttons.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              {buttons.map((button, index) => (
                <button
                  key={index}
                  type={button.type || 'button'}
                  style={{ ...styles.button, ...button.style }}
                  onClick={() => this.handleButtonClick(button.onClick)}
                >
                  {button.label || 'Button'}
                </button>
              ))}
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default BasicForm;
