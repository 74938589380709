import React, { Component } from 'react';
import BasicForm from '../../sharedComponents/basicForm';

const variableExtractMethodForm = [
  { label: 'Variable Name', type: 'text', name: 'variable_name' },
  { label: 'Parse Method', type: 'select', name: 'parse_method', options: ['', 'RAW', 'CSS_SELECTOR'] },
];

const objectExtractMethodForm = [
  { label: 'Storage Owner', type: 'select', name: 'storage_owner', options: ['', 'MANAGED', 
//   'BRING_YOUR_OWN_BUCKET'
] },
];

const bringYourOwnBucketForm = [
  { label: 'Bucket Name', type: 'text', name: 'byob_name' },
];

const styles = {
  additionalOptionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '5px',
    color: '#FFFFFF',
    border: '2px solid #dddddd',
  },
};

class StorageMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storage_owner: null,
    };
  }

  handleStorageMethodChange = (formValues) => {
    const { onChange } = this.props;
    this.setState({
      storage_owner: formValues.storage_owner,
    });

    if (onChange) {
      onChange(formValues);
    }
  }

  render() {
    const { storage_method } = this.props;
    const { storage_owner } = this.state;

    return (
      <div style={styles.additionalOptionsContainer}>
        {storage_method === 'VARIABLE' && (
          <BasicForm 
            fields={variableExtractMethodForm} 
            header='Variable Storage Configuration'
            onChange={this.handleStorageMethodChange}
            width="600px"
          />
        )}
        {storage_method === 'OBJECT' && (
          <BasicForm 
            fields={objectExtractMethodForm} 
            header='Object Storage Configuration'
            onChange={this.handleStorageMethodChange}
            width="600px"
          />
        )}
        {(storage_method === 'OBJECT' && storage_owner === 'BRING_YOUR_OWN_BUCKET') && (
          <BasicForm 
            fields={bringYourOwnBucketForm} 
            header='Bring Your Own Bucket'
            onChange={this.handleStorageMethodChange}
            width="600px"
          />
        )}
      </div>
    );
  }
}

export default StorageMethod;
