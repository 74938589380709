import React, { Component } from 'react';
import DataGrid from '../sharedComponents/dataGrid';
import BasicForm from '../sharedComponents/basicForm';
import StorageMethod from './scraperSubComps/storageMethod';
import ScheduleMethod from './scraperSubComps/scheduleMethod';
import CreditBar from './scraperSubComps/creditBar';

// 0.0005 per credit.

const inputForm = [
  { label: 'Scrape Name', type: 'text', name: 'scrape_name'},
  { label: 'URL', type: 'url', name: 'url' },
  { label: 'Extract Method', type: 'select', name: 'extract_method', options: ['', 'BASIC', 
  // 'JAVASCRIPT'
] },
  { label: 'Storage Method', type: 'select', name: 'storage_method', options: ['', 
  // 'VARIABLE', 
  'OBJECT'] },
  { label: 'Schedule Method', type: 'select', name: 'schedule_method', options: ['', 'ON DEMAND', 'SCHEDULED'] },

];

const styles = {
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '20px',
    gap: '20px',
    color: '#FFFFFF',
  },
  additionalOptionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '5px',
    color: '#FFFFFF',
    border: '2px solid #dddddd',
  },
  creditBar: {
    width: '30%'
  }
};


class ScraperSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule_method: null,
      extract_method: null,
      storage_method: null,
      storage_owner: null,
      utilizedCredits: 0,
      allottedCredits: 100,
    };
  }

  handleFormSubmit = (formValues) => {
    console.log(formValues)
    if (!formValues.scrape_name) {
      alert('Scrape ID must be provided.');
      return false
    }
    if (!formValues.url) {
      alert('URL must be provided.');
      return false
    }
    if (!formValues.schedule_method) {
      alert('Schedule Method must be provided.');
      return false
    }
    if (!formValues.storage_method) {
      alert('Storage Method must be provided.');
      return false
    }
    if (!this.state.storage_owner) {
      alert('Storage Owner must be provided.');
      return false
    }
    console.log('Form submitted with:', formValues);
  }

  handleTest = (formValues) => {
    if (!formValues.url) {
      alert('URL must be provided.');
      return false
    }

    console.log('TEST', formValues)
  
  }

  handleFormChange = (formValues) => {
    this.setState({
      schedule_method: formValues.schedule_method,
      extract_method: formValues.extract_method,
      storage_method: formValues.storage_method,
    });
  }

  handleScheduleSetupChange = (formValues) => {
    console.log('Schedule setup changed with:', formValues);
  }

  handleStorageSelectorChange = (formValues) => {
    this.setState({
      storage_owner: formValues.storage_owner,
    });
    console.log('Storage selector changed with:', formValues);
  }

  render() {
    const { schedule_method, storage_method } = this.state;

    return (
      <div>
        <div style={styles.creditBar}>
        <CreditBar 
            current={50} 
            total={100}
          />
        </div>
   
        <div style={styles.gridContainer}>

          <BasicForm 
            fields={inputForm} 
            header='Scrape Setup'
            buttons={[
              {
                label: 'Submit',
                type: 'submit',
                onClick: this.handleFormSubmit
              },
              {
                label: 'Test',
                type: 'submit',
                onClick: this.handleTest
              }
            ]}
            // onSubmit={this.handleFormSubmit} 
            onChange={this.handleFormChange}
            width="600px"
          />
          <div style={styles.additionalOptionsContainer}>
            <StorageMethod
              storage_method={storage_method}
              onChange={this.handleStorageSelectorChange}
            />
            {schedule_method === 'SCHEDULED' && (
              <ScheduleMethod
                onChange={this.handleScheduleSetupChange}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ScraperSetup;